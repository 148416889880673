// Const
import * as investmentConst from '../const/investment.const';
import {
  SET_COMPARTMENTS,
  SET_DISTRIBUTION_FEE_BANKS,
  SET_NOMINATIVE_STATUS,
} from '../const/investment.const';
import {
  postMockDocusign,
  postSignatureLink,
} from '../../../pages/sign-documents/api/signatures.api';
import {
  cancelOperation,
  confirmPaymentRelease,
  confirmPaymentToBeMade,
  confirmPaymentReceived,
  getInvestmentByLinkId as getInvestmentByLinkIdAPI,
  getInvestmentLinkById,
  getOperationFilters as getOperationFiltersAPI,
  putInvestment,
} from '../../api/investment.api';
import { initView as initViewBankDashboard } from '../../../pages/dashboard/Bank/actions/dashboard-bank.actions';
import { initView as initViewInvestorDashboard } from '../../../pages/dashboard/Investor/actions/dashboard-investor.actions';
import {
  getEmails,
  getLatestStep,
  getOperationComments,
  getUserInvestmentSuggested,
  postOperationComment,
  setModalCancelledOperationsIsOpen,
} from '../../../pages/dashboard/common/actions/dashboard.actions';
import store from '../../../store';
import { getCurrentStage } from '../../utils/JourneyUtils';
import { getDocumentsByInvestment } from './documents.actions';
import { getEntityPathFromUserType, User } from '../../utils/user';
import { OPERATION_STATUSES } from '../../utils/CatalizrConstants';

export const initInvestmentType = investmentTypeList => ({
  type: investmentConst.INIT_INVESTMENT_TYPE,
  investmentTypeList,
});
export const initInvestment = investment => ({
  type: investmentConst.INIT_INVESTMENT,
  investment,
});
export const initInvestmentBank = investor => ({
  type: investmentConst.INIT_INVESTMENT_BANK,
  investor,
});
export const setInvestmentTypeList = investmentTypeList => ({
  type: investmentConst.SET_INVESTMENT_TYPE_LIST,
  investmentTypeList,
});

export const setCurrentInvestmentType = investmentType => ({
  type: investmentConst.SET_CURRENT_INVESTMENT_TYPE,
  investmentType,
});

export const setCurrentInvestmentDisposalMode = investmentDisposalMode => ({
  type: investmentConst.SET_CURRENT_INVESTMENT_DISPOSAL_MODE,
  investmentDisposalMode,
});

export const initProduct = productList => ({ type: investmentConst.INIT_PRODUCT, productList });
export const setProductList = productList => ({
  type: investmentConst.SET_PRODUCT_LIST,
  productList,
});
export const setCurrentProduct = product => ({
  type: investmentConst.SET_CURRENT_PRODUCT,
  product,
});

export const setCurrentCompany = company => ({
  type: investmentConst.SET_CURRENT_COMPANY,
  company,
});

export const setInvestmentPartLevel = partLevel => ({
  type: investmentConst.SET_PART_LEVEL,
  partLevel,
});
export const setInvestmentCloseDate = investDate => ({
  type: investmentConst.SET_CLOSE_DATE,
  investDate,
});
export const setInvestmentRealEstateDate = ownershipDate => ({
  type: investmentConst.SET_REAL_ESTATE_DATE,
  ownershipDate,
});

// Set status of investment
const setInvestmentStatusPending = () => ({ type: investmentConst.SET_STATUS_PENDING });
const setInvestmentFullfilled = status => ({
  type: investmentConst.SET_STATUS_FULLFILED,
  status,
});
const setInvestmentRejected = () => ({ type: investmentConst.SET_STATUS_REJECTED });

const getOperationFiltersPending = () => ({ type: investmentConst.GET_OPERATION_FILTERS_PENDING });
const getOperationFiltersFullfilled = () => ({
  type: investmentConst.GET_OPERATION_FILTERS_FULLFILLED,
});
const getOperationFiltersRejected = () => ({
  type: investmentConst.GET_OPERATION_FILTERS_REJECTED,
});

export const setInvestmentStatus = (investmentId, status) => dispatch => {
  if (status === OPERATION_STATUSES.INVESTMENT_CLOSED) {
    dispatch(setInvestmentFullfilled(status));
    // Get date when investment is closed to display it on accordion
    let investmentLinkId = store.getState().investment.investment_link;
    dispatch(getLatestStep(investmentLinkId));
    return;
  } else {
    dispatch(setInvestmentStatusPending());
  }
  putInvestment(investmentId, { status: status })
    .then(() => {
      dispatch(setInvestmentFullfilled(status));
    })
    .catch(() => {
      dispatch(setInvestmentRejected());
    });
};

// methods to use to confirm the payment release

const confirmPaymentReleaseFullfilled = status => ({
  type: investmentConst.SET_STATUS_FULLFILED,
  status,
});

export const confirmThePaymentRelease =
  (investmentId, investmentLinkId, paymentEmittedComments) => dispatch => {
    dispatch(setInvestmentStatusPending());
    confirmPaymentRelease(investmentId)
      .then(() => {
        dispatch(confirmPaymentReleaseFullfilled(OPERATION_STATUSES.PAYMENT_EMITTED));
        dispatch(postOperationComment(investmentLinkId, paymentEmittedComments));
      })
      .catch(error => {
        console.error(`Error while confirming the payment release : ${error}`);
        dispatch(setInvestmentRejected());
      });
  };

export const confirmThePaymentToBeMade =
  (investmentId, investmentLinkId, paymentValidatedComments) => dispatch => {
    dispatch(setInvestmentStatusPending());
    confirmPaymentToBeMade(investmentId)
      .then(() => {
        dispatch(confirmPaymentReleaseFullfilled(OPERATION_STATUSES.PAYMENT_TO_BE_MADE));
        dispatch(postOperationComment(investmentLinkId, paymentValidatedComments));
      })
      .catch(error => {
        console.error(`Error while confirming the payment release : ${error}`);
        dispatch(setInvestmentRejected());
      });
  };

export const confirmThePaymentReceived =
  (investmentId, investmentLinkId, paymentValidatedComments) => dispatch => {
    dispatch(setInvestmentStatusPending());
    confirmPaymentReceived(investmentId)
      .then(() => {
        dispatch(confirmPaymentReleaseFullfilled(OPERATION_STATUSES.INVESTMENT_UPDATED));
        dispatch(postOperationComment(investmentLinkId, paymentValidatedComments));
      })
      .catch(error => {
        console.error(`Error while confirming the payment received : ${error}`);
        dispatch(setInvestmentRejected());
      });
  };

// Methods to mock DocuSign if there is no document to sign in company stage 2 : update investment status and send emails to backoffice & advisor

const customSignLinkPending = () => ({ type: investmentConst.CUSTOM_SIGNLINK_PENDING });
const customSignLinkFulfilled = () => ({
  type: investmentConst.CUSTOM_SIGNLINK_FULFILLED,
});
const customSignLinkRejected = () => ({ type: investmentConst.CUSTOM_SIGNLINK_REJECTED });

export const customSignLink = values => dispatch => {
  dispatch(customSignLinkPending());
  return postSignatureLink(
    undefined,
    values.investmentLinkId,
    values.signatoryType,
    values.user,
    values.UUID,
    getCurrentStage(),
  )
    .then(() => {
      dispatch(customSignLinkFulfilled());
    })
    .catch(() => {
      dispatch(customSignLinkRejected());
    });
};

const mockDocusignPending = () => ({ type: investmentConst.MOCK_DOCUSIGN_PENDING });
const mockDocusignFulfilled = () => ({ type: investmentConst.MOCK_DOCUSIGN_FULFILLED });
const mockDocusignRejected = () => ({ type: investmentConst.MOCK_DOCUSIGN_REJECTED });

export const mockDocusign = value => dispatch => {
  dispatch(mockDocusignPending);
  return postMockDocusign(value)
    .then(() => {
      dispatch(mockDocusignFulfilled());
    })
    .catch(() => {
      dispatch(mockDocusignRejected());
    });
};

export const getInvestmentByLinkId = linkId => dispatch => {
  dispatch(getInvestmentLinkById(linkId));
};

export const setInvestmentLinkId = investmentLinkId => ({
  type: investmentConst.SET_INVESTMENT_LINK_ID,
  idLink: investmentLinkId,
});

export const postCancelOperation = (investmentLinkId, comment, isDraft = false) => ({
  type: investmentConst.POST_CANCELLED,
  payload: cancelOperation(investmentLinkId, comment, isDraft),
});

// function used to force the refresh of the dashboard data (advisor, backoffice or investor users)
const _refreshUserDashboard = async (userData, dispatch) => {
  const userType = getEntityPathFromUserType(userData.type);
  if (userType === User.BANK) {
    dispatch(initViewBankDashboard());
  } else if (userType === User.INVESTOR) {
    dispatch(initViewInvestorDashboard(userData.email));
  }
};

export const submitCancelOperation =
  (investmentLinkId, refreshInvestmentCancelled = true, isDraft = false) =>
  async (dispatch, getState) => {
    try {
      const comment = getState().investment.currentComment;
      const result = await dispatch(postCancelOperation(investmentLinkId, comment, isDraft));
      // Post comment after cancel on not draft op
      if (!isDraft && comment?.length > 0) {
        dispatch(postOperationComment(investmentLinkId, comment));
      }

      if (result.value.status === 201 && refreshInvestmentCancelled && !isDraft) {
        const investment = await getInvestmentByLinkIdAPI(investmentLinkId);
        dispatch(initInvestment(investment.data.data));
        // Get step date to display cancelled date on accordion
        dispatch(getLatestStep(investmentLinkId));
      }
      if (!refreshInvestmentCancelled) {
        _refreshUserDashboard(getState().user, dispatch);
      }

      if (result.value.status === 201 && isDraft && refreshInvestmentCancelled) {
        dispatch(getUserInvestmentSuggested());
      }

      dispatch(setModalCancelledOperationsIsOpen(false));
    } catch (err) {
      console.error('Error while cancelling operation', err);
    }
  };

export const submitRejectOperation =
  (investmentLinkId, investmentId, refresh = true) =>
  async (dispatch, getState) => {
    if (refresh) {
      try {
        const investment = await getInvestmentByLinkIdAPI(investmentLinkId);
        dispatch(initInvestment(investment.data.data));
        dispatch(getLatestStep(investmentLinkId));
        dispatch(getOperationComments(investment.data.data.sql_id));
        dispatch(getEmails(investmentLinkId));
        dispatch(getDocumentsByInvestment(investmentId));
      } catch (err) {
        console.error('Error while cancelling operation', err);
        window.location.reload();
      }
    }
    if (!refresh) {
      _refreshUserDashboard(getState().user, dispatch);
    }
  };

export const getOperationFilters = async (
  type,
  utorizeId,
  email,
  creation_method,
  status,
  dispatch,
) => {
  dispatch(getOperationFiltersPending());
  return getOperationFiltersAPI(type, utorizeId, email, creation_method, status)
    .then(data => {
      dispatch(getOperationFiltersFullfilled());
      return data;
    })
    .catch(error => {
      dispatch(getOperationFiltersRejected());
      throw error;
    });
};

export const setCompartments = compartments => ({
  type: SET_COMPARTMENTS,
  compartments,
});

export const setNominativeStatuses = nominativeStatuses => {
  return {
    type: SET_NOMINATIVE_STATUS,
    nominativeStatuses,
  };
};

export const setDistributionFeeBanks = distributionFeeBanks => {
  return {
    type: SET_DISTRIBUTION_FEE_BANKS,
    distributionFeeBanks,
  };
};

export const setInvestmentCategories = categories => {
  return {
    type: investmentConst.SET_INVESTMENT_CATEGORIES,
    payload: categories,
  };
};

export const setAdvisorInCharge = email => ({
  type: investmentConst.SET_ADVISOR_IN_CHARGE,
  payload: email,
});
