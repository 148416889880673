// à déplacer dans const sharing lib
export const FEATURE_FLAG = {
  display_survey: 'display.survey',
  display_investor_birth: 'display.investor_birth',
  display_occupation: 'display.occupation',
  display_giin_number: 'display.giin_number',
  display_ppe: 'display.ppe',
  display_investment_ownership: 'display.investment_ownership',
  display_investor_confirmation_text: 'display.investor_confirmation_text',
  display_investor_tax_fields: 'display.investor_tax_fields',
  display_ex_post_costs: 'display.ex_post_costs',
  display_ex_post_costs_mailing: 'display.ex_post_costs_mailing',
  display_politically_exposed_persons: 'display.politically_exposed_persons',
  display_investor_categorization: 'display.investor_categorisation',
  display_investor_status: 'display.investor_status',
  display_investor_current_account: 'display.investor_current_account',
  display_experimental: 'display.feature_experimental',
  display_funding_appeal: 'display.funding_appeal',
};
