import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

// Components
import Topbar from '../../../components/Topbar';

// Utils
import { getSubdomain } from '../../../shared/utils/helper';

// CSS styles
import '../styles.scss';

const LegalNotice = props => {
  useEffect(() => {
    const { initView } = props;
    initView();
  }, []);

  const { bank } = props;
  const siteName = `${getSubdomain()}.catalizr.eu`;
  return (
    <div>
      <Topbar hideAccountInfos />
      <div className="container legal-notice__legalNotice">
        <div className="row legal-notice__legalNoticeBank">
          <p>
            Vous êtes actuellement connecté au site <b>{siteName}</b> qui est édité par{' '}
            <b>UTOCAT</b> pour le compte <b>{bank.name}</b> dont les mentions légales sont
            disponibles à cette adresse :{' '}
            <a href={bank.legal_notice_link} target="_blank" rel="noopener noreferrer">
              {bank.legal_notice_link}
            </a>
          </p>
        </div>
        <div className="row mt-5">
          <ul>
            <li>
              <b>Editeur du site</b>
            </li>
            <li>Dénomination sociale : UTOCAT</li>
            <li>Représentant légal : Clément Francomme</li>
            <li>Société anonyme au capital de : 13 196 euros</li>
            <li>RCS : Lille Métropole 807 681 424</li>
            <li>Siège social : 165 avenue de Bretagne - 59000 Lille</li>
          </ul>
        </div>
        <div className="row mt-3">
          <ul>
            <li>
              <b>Directeur de la publication :</b>
              <span> Clément Francomme</span>
            </li>
          </ul>
        </div>
        <div className="row mt-3">
          <ul>
            <li>
              <b>Hébergeur du site</b>
            </li>
            <li>Dénomination sociale : CleverCloud</li>
            <li>Représentant légal : Clément Nivolle</li>
            <li>SAS au capital de : 17 625 euros</li>
            <li>RCS : Nantes B 524 172 699</li>
            <li>Siège social : 3 rue de l’Allier - 44000 Nantes</li>
          </ul>
        </div>
        {/* <div className="row legal-notice__legalNoticeBank"> // Back button
            <Button
              text="Retour"
              cssId="legal-notice__back-btn"
              onClick={() => {
                history.goBack();
              }}
            />
            </div>*/}
      </div>
    </div>
  );
};

LegalNotice.propTypes = {
  bank: PropTypes.shape({
    legal_notice_link: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
  initView: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

export default LegalNotice;
