import { deleteMethod, get, post, put } from './apicaller';
import store from '../../store';
import { CREATION_METHOD, DASHBOARD_STATUSES, FUND_COMPANY_TYPE } from '../utils/CatalizrConstants';
import _ from 'lodash';

// Utils
import { isCompanyFinalStage } from '../utils/JourneyUtils';
import { buildUrlQuery } from '../utils/helper';

export const getInvestmentLinkById = investmentLinkId =>
  get(`${process.env.REACT_APP_BACKEND_URL}/api/investments/links/${investmentLinkId}`, [404]);

/**
 * Get the investment with documents according to stage & signatory (optionals)
 * @param investmentLinkId
 * @param {object} options optionals parameters for filtering returned documents
 * The accepted parameters are :
 * - signatory {string} : investor OR company
 * - stage {number} : 1 OR 2
 */
export const getInvestmentByLinkId = (investmentLinkId, options = {}) => {
  let endpoint = `investments/${options.isDraftMode ? 'links-draft' : 'links'}/${investmentLinkId}`;

  if (options.signatory && !options.stage) {
    endpoint += `?signatory=${options.signatory}`;
  }
  if (options.stage && !options.signatory) {
    endpoint += `?stage=${options.stage}`;
  }
  if (options.signatory && options.stage) {
    endpoint += `?signatory=${options.signatory}&stage=${options.stage}`;
  }
  return get(`${process.env.REACT_APP_BACKEND_URL}/${endpoint}`, [404]);
};

export const getInvestmentById = (investmentId, expand, headers = undefined) => {
  return get(
    `${process.env.REACT_APP_BACKEND_URL}/investments/${investmentId}${
      expand ? '?expand=' + expand.join(',') : ''
    }`,
    headers,
    [],
  );
};

export const postInvestmentLink = (data, subdomain) => {
  return post(
    `${process.env.REACT_APP_BACKEND_URL}/investments/links?origin=${subdomain}`,
    data,
    [],
  );
};

/**
 *
 * @param {*} linkId
 */
export const updateInvestmentLinkDestination = linkId =>
  put(
    `${process.env.REACT_APP_BACKEND_URL}/api/investments/links/${linkId}/update-destination`,
    {},
    [],
  );

/**
 *
 * @param {*} linkId
 * @param entityType
 * @param directCessionTransmit
 */
export const sendInvestToInvestor = (linkId, entityType, directCessionTransmit = false) =>
  post(
    `${process.env.REACT_APP_BACKEND_URL}/api/investments/links/${linkId}/send/investor`,
    {
      entity_type: entityType,
      direct_fill_cession: directCessionTransmit,
    },
    [],
  );

export const deleteInvestmentLinkDocument = (investLinkId, documentId, data) =>
  deleteMethod(
    `${process.env.REACT_APP_BACKEND_URL}/api/investments/links/${investLinkId}/documents/${documentId}`,
    data,
    [],
  );

/**
 * Retourne la liste des types d'investissement
 * @param {string} bank Par default affiche la banque du sous domaine lorsque ''
 * sinon 'all' pour afficher les types d'investissements de toutes les banques.
 * @param {boolean} sharedDoc 0 retourne toute les opérations sinon '1'
 * cela retourne les opérations shared=true uniquement
 * @param subDomain
 * @return {string[]} une liste de types d'investiemment
 */
export const getInvestmentTypeList = (bank, sharedDoc, subDomain) => {
  let params = '?shared_doc=';

  // /!\ Pris en compte seulement si bank=all
  if (sharedDoc) {
    params += '1';
  } else {
    params += '0';
  }

  if (bank) {
    params += `&bank=all`;
  }
  if (subDomain) {
    params = `${params}&bank=${subDomain}`;
  }

  return get(`${process.env.REACT_APP_BACKEND_URL}/operationslist${params}`, []);
};

/**
 * Retourne une liste de produit en fonction d'un type d'investissement
 * @param {string} investmentType le type d'investissement (Création de société OU Augmentation de capital)
 * @return {string[]} une liste de produits
 */
export const getProductList = investmentType =>
  get(
    `${process.env.REACT_APP_BACKEND_URL}/productslist?operation=${encodeURIComponent(
      investmentType,
    )}`,
    [],
  );

/**
 *
 * @param {*} operationId
 * @param {*} investment
 */
export const putInvestment = (operationId, investment) => {
  return put(
    `${process.env.REACT_APP_BACKEND_URL}/api/investments/${operationId}`,
    { ...investment },
    [],
  );
};

/**
 * Function to update an investment link (transmit operation) and send emails
 * @param {string} linkId
 * @param {object} datas : advisor email, investor email, operation type, payment mode and bank agency
 */
export const putInvestmentLink = (linkId, datas) =>
  put(`${process.env.REACT_APP_BACKEND_URL}/investments/links/${linkId}`, datas, []);

/**
 * Allow the advisor to confirm the payment release.
 * @param {string} operationId the ID of the investment to process
 * @return promise
 */
export const confirmPaymentRelease = operationId =>
  post(`${process.env.REACT_APP_BACKEND_URL}/api/investments/${operationId}/transfer`, {}, []);

export const confirmPaymentToBeMade = operationId =>
  post(
    `${process.env.REACT_APP_BACKEND_URL}/api/investments/${operationId}/transferToBeMade`,
    {},
    [],
  );

export const confirmPaymentReceived = operationId =>
  post(
    `${process.env.REACT_APP_BACKEND_URL}/api/investments/${operationId}/transferReceived`,
    {},
    [],
  );

export const finishInvestment = (operationId, data) => {
  return post(
    `${process.env.REACT_APP_BACKEND_URL}/operations/${operationId}/finish`,
    {
      isin: data ? data.investment_isin : null,
      managementType: data ? data.management_type : null,
    },
    [],
  );
};

export const getSharedOperationList = (documentType, operationType) => {
  let queryParams = '';
  const redux = store.getState().modalAddSharedDoc;
  if (redux && redux.userCurrentCompany && redux.userCurrentCompany.company_mongo_id) {
    queryParams = `?company=${redux.userCurrentCompany.company_mongo_id}`;
  }
  return get(
    `${process.env.REACT_APP_BACKEND_URL}/operations/type/${operationType}/missingdocument/${documentType}${queryParams}`,
    {},
  );
};

export const rejectInvestment = (investmentId, currentComment) =>
  post(
    `${process.env.REACT_APP_BACKEND_URL}/operations/${investmentId}/reject`,
    { comment: currentComment },
    [],
  );

// ------------------------------ ROUTES FOR OPERATION VALIDATIONS ---------------------------------
/**
 * Function to used to process a repurchase operation.
 * @param {*} payload
 */
export const processRepurchaseOperation = payload => {
  if (typeof payload.company.user.id !== 'number') {
    delete payload.company.user.id;
  }
  return post(`${process.env.REACT_APP_BACKEND_URL}/operations/repurchase/validate`, payload, []);
};

export const processCreateOperation = payload => {
  //TODO: Refacto - Ne pas envoyer les props directement.
  if (payload && payload.company && payload.company.user) {
    delete payload.company.user.level;
    delete payload.company.user.type;
    delete payload.company.user.logged;
    delete payload.company.user.getUserInfoLoading;
    delete payload.company.user.updatedAtDisplay;
    if (typeof payload.company.user.id !== 'number') {
      delete payload.company.user.id;
    }
    // Fix à faire côté back-end plus tard (permet la fusion de companies au besoin)
    if (isCompanyFinalStage()) {
      delete payload.company.id;
    }
  }
  return post(`${process.env.REACT_APP_BACKEND_URL}/operations/create/validate`, payload, []);
};

export const processIncreaseOperation = payload => {
  // Quick fix for multi-compte company
  if (
    payload &&
    payload.company &&
    payload.company.user &&
    typeof payload.company.user.id !== 'number'
  )
    delete payload.company.user.id;

  return post(`${process.env.REACT_APP_BACKEND_URL}/operations/increase/validate`, payload, []);
};

export const processDisposalOperation = payload => {
  return post(`${process.env.REACT_APP_BACKEND_URL}/operations/disposal/validate`, payload, []);
};

export const processBondOperation = payload => {
  return post(`${process.env.REACT_APP_BACKEND_URL}/operations/bond/validate`, payload, []);
};

export const getInvestmentStepHistory = InvestmentLinkId => {
  return get(`${process.env.REACT_APP_BACKEND_URL}/investments/${InvestmentLinkId}/stepsEmail`, {});
};

export const cancelOperation = (investLinkId, comment, isDraft = false) =>
  post(
    `${process.env.REACT_APP_BACKEND_URL}${
      isDraft ? '/draft' : ''
    }/operations/${investLinkId}/cancel`,
    {
      comment,
    },
  );

export const getPlatineDatas = uuid =>
  get(`${process.env.REACT_APP_BACKEND_URL}/api/investments/${uuid}/platine`, {});

export const getOperationFilters = async (type, utorizeId, email, creation_method, status) => {
  // query
  let queryParams =
    typeof creation_method === 'string' ? `?creation_method=${creation_method}` : '';
  let separatorPathQuery = queryParams === '' ? '?' : '&';
  let urlApi = '';
  switch (status) {
    case DASHBOARD_STATUSES.CORPORATE_ACTIONS:
      urlApi = `${type}/${utorizeId}/shares-valuation/filters`;
      break;
    case DASHBOARD_STATUSES.SHAREHOLDING:
      urlApi = `${type}/${utorizeId}/shareholding/filters`;
      break;
    case DASHBOARD_STATUSES.FUND_MANAGEMENT:
      urlApi = `fund-summary/selectors/filters`;
      break;
    // case DASHBOARD_STATUSES.OPERATION_TEMPLATES:
    //FIXME operation model ?
    default:
      urlApi = `${type}/${utorizeId}/operations/filters`;
      queryParams =
        creation_method !== CREATION_METHOD.TRANSMIT
          ? `${queryParams}${separatorPathQuery}status=${status}`
          : queryParams;
      separatorPathQuery = queryParams === '' ? '?' : '&';
      break;
  }
  queryParams =
    type === 'investors'
      ? `${queryParams}${separatorPathQuery}investor_email=${encodeURIComponent(email)}`
      : queryParams;

  return get(`${process.env.REACT_APP_BACKEND_URL}/api/${urlApi}${queryParams}`, {});
};

export const getPartTypesList = (operation, product) =>
  get(
    `${process.env.REACT_APP_BACKEND_URL}/api/documenttree/parttypelist?operation=${operation}&product=${product}`,
    {},
  );

/**
 * Get part types list
 * @param {string} operationTypeId Operation type id
 * @param {string} portfolioTypesId Portfolio types ids in a string separate with ","
 * @param {string} activatedBanksId Banks ids in a string separate with ","
 * @returns {array} part type list
 */
export const getPartTypesListByBanks = (operationTypesId, portfolioTypesId, activatedBanksId) =>
  get(
    buildUrlQuery(`${process.env.REACT_APP_BACKEND_URL}/banks/part-type`, {
      banksId: activatedBanksId,
      operationTypesId,
      portfolioTypesId,
    }),
    {},
  );

/**
 * retourne la liste des compartiments.
 */
export const retrieveCompartments = async () =>
  get(`${process.env.REACT_APP_BACKEND_URL}/api/investments/compartments`, []);

/**
 * retourne la liste des statuts nominatifs.
 */
export const retrieveNominativeStatuses = async () =>
  get(`${process.env.REACT_APP_BACKEND_URL}/api/investments/nominative-status`, []);

export const getDistributionFeeBanks = async () =>
  get(`${process.env.REACT_APP_BACKEND_URL}/api/investments/distribution-fee-banks`, []);

export const processPrivateEquityOperation = payload => {
  if (payload && payload.company && payload.company.user) {
    delete payload.company.user.level;
    delete payload.company.user.type;
    delete payload.company.user.logged;
    delete payload.company.user.getUserInfoLoading;
    delete payload.company.user.updatedAtDisplay;
    if (typeof payload.company.user.id !== 'number') {
      delete payload.company.user.id;
    }
    // Fix à faire côté back-end plus tard (permet la fusion de companies au besoin)
    if (isCompanyFinalStage()) {
      delete payload.company.id;
    }
    payload.company['is_private_equity'] = true;
    payload.investment['is_private_equity'] = true;
    payload.company['type'] = FUND_COMPANY_TYPE;
    payload.company['iban'] = _.get(payload, 'company.iban', '');
    payload.investment['iban'] = _.get(payload, 'investment.iban', '');
    payload.investors[0]['iban'] =
      payload.investors[0].iban === null ? '' : _.get(payload, 'investors[0].iban', '');
    payload.investors[1]['iban'] =
      payload.investors[1].iban === null ? '' : _.get(payload, 'investors[1].iban', '');
  }
  return post(`${process.env.REACT_APP_BACKEND_URL}/operations/create/validate`, payload, []);
};

export const processKindContributionOperation = payload => {
  return post(
    `${process.env.REACT_APP_BACKEND_URL}/operations/kindcontribution/validate`,
    payload,
    [],
  );
};

export const getInvestmentCategories = () =>
  get(`${process.env.REACT_APP_BACKEND_URL}/api/investments/categories`, []);

export const putInvestmentLinkAdvisorInCharge = (link_id, email) =>
  put(`${process.env.REACT_APP_BACKEND_URL}/api/investments/links/${link_id}/advisor-in-charge`, {
    advisor_in_charge_email: email,
  });
